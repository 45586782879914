import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Text } from "shared/components/Text";
import ReviewSlider from "shared/components/sliders/ReviewSlider";

import css from "./styles.module.scss";

export const ReviewSection = ({ passedRef }) => {
  const { t, i18n } = useTranslation();
  const { city } = useParams();

  const content = useMemo(() => {
    return t(`reviewSection.reviews.${city}`, {
      returnObjects: true,
    });
  }, [i18n.language, city]);

  return (
    <div ref={passedRef} className={css.ReviewSection}>
      <div className={css.contentBox}>
        <Text
          type="white"
          className={css.title}
          bold
          alignCenter
          secondaryFamily
        >
          {t("reviewSection.title")}
        </Text>
        <ReviewSlider data={content} />
      </div>
    </div>
  );
};
