import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setupPlan4u } from "shared/utils";
import { getLocations } from "features/LocationsPage/locations";
import { LanguageChooser } from "shared/components/LanguageChooser";
import { Header } from "shared/components/BasicHeader";
import { Spinner } from "shared/components/Spinner";
import { Text } from "shared/components/Text";
import Slider from "shared/components/sliders/Slider";

import css from "./styles.module.scss";
import classNames from "classnames";

export const BookingPage = () => {
  const { t, i18n } = useTranslation();
  const { city } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showIframe, setShowIframe] = useState(true);

  const locations = useMemo(() => {
    return getLocations();
  }, [i18n.language]);

  const bookingUrl = useMemo(() => {
    return locations[city].bookingUrl[i18n.language];
  }, [i18n.language, locations, city]);

  const banners = useMemo(() => {
    return locations[city].banners[i18n.language] || [];
  }, [locations, city, i18n.language]);

  const navigate = useNavigate();

  return (
    <div className={css.BookingPage}>
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          className={css.languageChooser}
          shouldScrollUp
          borderColor="blue"
        />
      </div>
      <Header city={city} cityName={locations[city].title} />
      {banners.length === 1 ? (
        <img src={banners[0]} className={css.banner}></img>
      ) : (
        <Slider data={banners} />
      )}
      {isLoading && <Spinner type="small" />}
      <iframe
        id="fBookIt"
        src={bookingUrl}
        width="100%"
        frameBorder="0"
        scrolling="no"
        className={css.iframe}
        onLoad={() => {
          setupPlan4u();
          setIsLoading(false);
        }}
      ></iframe>
    </div>
  );
};
