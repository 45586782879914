import React from "react";
import AwesomeSlider from "react-awesome-slider";
import AwesomeSliderStyles from "react-awesome-slider/src/styles";
import withAutoplay from "react-awesome-slider/dist/autoplay";

import css from "./styles.module.scss";

const AUTOPLAY_INTERVAL = 10000;

const Slider = ({ data }) => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const isMobile = window.matchMedia("(max-width: 1060px)").matches;

  return (
    <AutoplaySlider
      cssModule={AwesomeSliderStyles}
      className={css.bannerContainer}
      bullets={isMobile}
      organicArrows={true}
      play={true}
      interval={AUTOPLAY_INTERVAL}
      mobileTouch={true}
    >
      {data.map((banner, index) => (
        <div key={index} className={css.bannerItem}>
          <img
            src={banner}
            alt={`Banner ${index}`}
            className={css.bannerImage}
          />
        </div>
      ))}
    </AutoplaySlider>
  );
};

export default Slider;
