import cn from "classnames";

import css from "./styles.module.scss";
import { Button } from "shared/components/Button";
import { Text } from "shared/components/Text";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { LoadingOverlay } from "shared/components/LoadingOverlay";

export const CancelOverlay = ({
  setShouldShowCancelOverlay,
  bookingId,
  personId,
  apiUrl,
  onFail,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const cancelBooking = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${apiUrl}/o_cancelBooking/${bookingId}/${personId}`
      );

      const json = await response.json();
      if (json.status === "success") {
        onSuccess();
      } else {
        onFail();
      }
    } catch (error) {
      onFail();
    }

    setIsLoading(false);
    setShouldShowCancelOverlay(false);
  };

  if (isLoading) {
    return (
      <div className={css.CancelOverlay}>
        <LoadingOverlay />
      </div>
    );
  }

  return (
    <div className={css.CancelOverlay}>
      <button
        className={css.background}
        onClick={() => setShouldShowCancelOverlay(false)}
      />
      <div className={css.popup}>
        <Text type="white" bold alignCenter className={css.text}>
          {t("shared.cancelPrompt")}
        </Text>
        <div className={css.buttonsContainer}>
          <Button
            text={t("shared.confirmCancel")}
            onClick={cancelBooking}
            className={cn(css.button, css.confirmButton)}
            textClassname={css.text}
          />
          <Button
            text={t("shared.denyCancel")}
            onClick={() => {
              setShouldShowCancelOverlay(false);
            }}
            className={cn(css.button, css.denyButton)}
            textClassname={css.text}
          />
        </div>
      </div>
    </div>
  );
};
