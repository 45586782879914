import warsawBackgroundVideo from "assets/videos/background-video-placeholder-warsaw-v2.mp4";
import wroclawBackgroundVideo from "assets/videos/background-video-placeholder-wroclaw.mp4";
import warsawBackgroundMobileVideo from "assets/videos/mobile-background-video-warsaw-v2.mp4";
import wroclawBackgroundMobileVideo from "assets/videos/mobile-background-video-wroclaw.mp4";
import gdanskBackgroundVideo from "assets/videos/background-video-placeholder-gdansk.mp4";
import gdanskBackgroundMobileVideo from "assets/videos/mobile-background-video-gdansk.mp4";
import katowiceBackgroundVideo from "assets/videos/background-video-placeholder-katowice.mp4";
import katowiceBackgroundMobileVideo from "assets/videos/mobile-background-video-katowice.mp4"
import priceBanner20Pl from "assets/images/banners/banner-price-20-pl.png";
import priceBanner20Eng from "assets/images/banners/banner-price-20-eng.png"
import priceBanner25 from "assets/images/priceBanners/price-banner-25-v5.png";
import priceBanner30 from "assets/images/priceBanners/price-banner-30.png";
import priceBanner35 from "assets/images/priceBanners/price-banner-35-pl.png"
import banner50DiscountEng from "assets/images/banners/banner-50-discount-eng.png";
import banner50DiscountPl from "assets/images/banners/banner-50-discount-pl.png";
import bannerStudentpxlDiscountEng from "assets/images/banners/banner-studentpxl-discount-eng-v3.png";
import bannerStudentpxlDiscountPl from "assets/images/banners/banner-studentpxl-discount-pl-v3.png";
import bannerStudia30DiscountEng from "assets/images/banners/banner-studia30-discount-eng-v3.png";
import bannerStudia30DiscountPl from "assets/images/banners/banner-studia30-discount-pl-v3.png";
import bannerPixelBoxPl from "assets/images/banners/banner-pixel-box-pl-v2.png";
import bannerPixelBoxEng from "assets/images/banners/banner-pixel-box-eng-v2.png";
import bannerNextSeasonPl from "assets/images/banners/banner-new-season-pl.png";
import bannerNextSeasonEng from "assets/images/banners/banner-new-season-eng.png";


import i18n from "i18n";

// siteUrl should be '/key', where key is the key of the location in the locations object
// this is because MainPage bases on the location param to render the correct location

export const getLocations = () => {
  return {
    wroclaw: {
      title: "WROCŁAW",

      // site data
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/xl/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/xl/bookItNEW_PL.html",
      },
      backgroundVideo: wroclawBackgroundVideo,
      backgroundMobileVideo: wroclawBackgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/772339493?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/772344269?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/xl/api/v1",
      siteUrl: "/wroclaw",
      siteTitle: "Pixel XL Wroclaw",
      arenaWithCredits: false,
      googleMapsOptions: {
        latitude: 51.10697301550853,
        longitude: 17.03222937059691,
        zoom: 15,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/wroclaw/about-us/about-picture-2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/wroclaw/about-us/about-picture-4.v2.jpg",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/wroclaw/about-us/about-picture-6.jpg",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-4.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-6.jpg",
      ],
      banners: {
        pl: [bannerNextSeasonPl],
        eng: [bannerNextSeasonEng]
      },
      priceBanner: priceBanner20Pl,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Świdnicka 12, 50-068 Wrocław",
      email: "wroclaw@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/xl/getVoucher.html",
    },
    katowice: {
      title: "KATOWICE",

      // site data
      siteUrl: "/katowice",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_PL.html",
      },
      backgroundVideo: katowiceBackgroundVideo,
      backgroundMobileVideo: katowiceBackgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/1049254004?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/1049265772?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/katowice/api/v1",
      siteTitle: "Pixel XL Katowice",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 50.262872,
        longitude: 19.020316,
        zoom: 16,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/katowice/about-us/about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/katowice/about-us/about-picture-4-v2.png",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/katowice/about-us/about-picture-6-v2.jpg",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/katowice/about-us/mobile-about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/katowice/about-us/mobile-about-picture-4-v2.png",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/katowice/about-us/mobile-about-picture-6-v2.jpg",
      ],
      banners: {
        pl: [bannerStudia30DiscountPl,banner50DiscountPl,bannerNextSeasonPl],
        eng: [bannerStudia30DiscountEng,banner50DiscountEng,bannerNextSeasonEng]
      },
      priceBanner: priceBanner35,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Sokolska 31, 40-086 Katowice",
      email: "katowice@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/katowice/getVoucher.html",
      // classesCatalog: "/documents/katowice/classes-v3.pdf",
      // classesStatue: "/documents/katowice/classes-statue.pdf",
    },
    slask: {
      title: "KATOWICE",
      // site data
      siteUrl: "/slask",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_PL.html",
      },
      backgroundVideo: katowiceBackgroundVideo,
      backgroundMobileVideo: katowiceBackgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/1049254004?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/1049265772?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/katowice/api/v1",
      siteTitle: "Pixel XL Slask",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 50.262872,
        longitude: 19.020316,
        zoom: 16,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/katowice/about-us/about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/katowice/about-us/about-picture-4-v2.png",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/katowice/about-us/about-picture-6-v2.jpg",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/katowice/about-us/mobile-about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/katowice/about-us/mobile-about-picture-4-v2.png",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/katowice/about-us/mobile-about-picture-6-v2.jpg",
      ],
      banners: {
        pl: [bannerStudia30DiscountPl,banner50DiscountPl,bannerNextSeasonPl],
        eng: [bannerStudia30DiscountEng,banner50DiscountEng,bannerNextSeasonEng]
      },
      priceBanner: priceBanner35,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Sokolska 31, 40-086 Katowice",
      email: "katowice@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/katowice/getVoucher.html",
      // classesCatalog: "/documents/katowice/classes-v3.pdf",
      // classesStatue: "/documents/katowice/classes-statue.pdf",
    },
    warszawa: {
      title: "WARSZAWA",
      label: "Pixel XL BOX",

      // site data
      siteUrl: "/warszawa",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/warszawa/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/warszawa/bookItNEW_PL.html",
      },

      backgroundVideo: warsawBackgroundVideo,
      backgroundMobileVideo: warsawBackgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/1020127654?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/1020128108?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/warszawa/api/v1",
      siteTitle: "Pixel XL Warszawa",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 52.25625,
        longitude: 20.983733,
        zoom: 16,
      },
      aboutUsImages: [
        "/images/warszawa/about-us/about-picture-1.v2.jpg",
        "/images/warszawa/about-us/about-picture-2.v2.jpg",
        "/images/warszawa/about-us/about-picture-3.v2.jpg",
        "/images/warszawa/about-us/about-picture-4.v2.jpg",
        "/images/warszawa/about-us/about-picture-5.v2.jpg",
        "/images/warszawa/about-us/about-picture-6.v2.jpg",
      ],
      aboutUsMobileImages: [
        "/images/warszawa/about-us/about-picture-1.v2.jpg",
        "/images/warszawa/about-us/about-picture-2.v2.jpg",
        "/images/warszawa/about-us/about-picture-3.v2.jpg",
        "/images/warszawa/about-us/about-picture-4.v2.jpg",
        "/images/warszawa/about-us/about-picture-5.v2.jpg",
        "/images/warszawa/about-us/about-picture-6.v2.jpg",
      ],
      banners: {
        pl: [priceBanner20Pl,banner50DiscountPl,bannerPixelBoxPl,bannerNextSeasonPl],
        eng: [priceBanner20Eng,banner50DiscountEng,banner50DiscountEng,bannerNextSeasonEng]
      },
      priceBanner: priceBanner25,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: false,
      birthdayOfferVisibility: false,
      schoolOfferVisibility: true,

      address: "al. Jana Pawła II 82, 00-175 Warszawa",
      email: "warszawa@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/warszawa/getVoucher.html",
    },
    gdansk: {
      title: "TRÓJMIASTO",
      label: i18n.t("locationsPage.novelty"),

      // site data
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/gdansk/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/gdansk/bookItNEW_PL.html",
      },
      backgroundVideo: gdanskBackgroundVideo,
      backgroundMobileVideo: gdanskBackgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/1049248132?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/1049251342?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/gdansk/api/v1",
      siteUrl: "/gdansk",
      siteTitle: "Pixel XL Gdańsk",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 54.40439190823928,
        longitude: 18.57099491269834,
        zoom: 15,
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/gdansk/about-us/about-picture-v2.png",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/gdansk/about-us/about-picture-v3.png",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/gdansk/about-us/about-picture-v4.jpg",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/gdansk/about-us/mobile-about-picture-v2.png",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/gdansk/about-us/mobile-about-picture-v3.png",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/gdansk/about-us/mobile-about-picture-v4.jpg",
      ],
      banners: {
        pl: [bannerStudentpxlDiscountPl,banner50DiscountPl,bannerNextSeasonPl],
        eng: [bannerStudentpxlDiscountEng,banner50DiscountEng,bannerNextSeasonEng]
      },
      priceBanner: priceBanner35,
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      address: "Al. Grunwaldzka 472f (Prime B), 80-309 Gdańsk",
      email: "gdansk@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/gdansk/getVoucher.html",
    },
    poznan: {
      title: "POZNAŃ",
      label: "2025",
      siteUrl: "/poznan",
    },
    krakow: {
      title: "KRAKÓW",
      label: "2025",
      siteUrl: "/krakow",
    },
  };
};
