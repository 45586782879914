import i18n from "i18n";

export const getOffers = () => [
  {
    name: "Game Designer",
    city: i18n.t("careerSection.wroclaw"),
    link: "https://justjoin.it/job-offer/pixel-xl-game-designer-wroclaw-game",
  },
  {
    name: i18n.t("careerSection.jobs.accountant"),
    city: i18n.t("careerSection.wroclaw"),
    link: "https://www.pracuj.pl/praca/ksiegowy-ksiegowa-wroclaw,oferta,1003708977",
  },
  {
    name: i18n.t("careerSection.jobs.customerService"),
    city: i18n.t("careerSection.gdansk"),
    link: "https://www.olx.pl/oferta/praca/host-obsluga-klienta-pixel-xl-trojmiasto-CID4-ID12B1xa.html?bs=olx_pro_listing",
  },
  {
    name: "Game Developer",
    city: i18n.t("careerSection.wroclaw"),
    link: "https://justjoin.it/job-offer/pixel-xl-interactive-mat-game-developer-wroclaw-game",
  },
  {
    name: i18n.t("careerSection.jobs.callCenter"),
    city: i18n.t("careerSection.wroclaw"),
    link: "https://www.olx.pl/oferta/praca/pixel-xl-pracownik-call-center-CID4-ID11RrAW.html?bs=olx_pro_listing",
  },
];
