import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

import warsawBanner from "assets/images/banners/banner-pixel-box-pl-v2.png";

export const AboutSection = ({ passedRef, images, mobileImages, city }) => {
  const { t } = useTranslation();

  const contentBeforeImages = t(`aboutSection.contentBeforeImages.${city}`, {
    returnObjects: true,
  });
  const contentAfterImages = t("aboutSection.contentAfterImages", {
    returnObjects: true,
  });

  const isMobile = window.matchMedia("(max-width: 990px)").matches;

  return (
    <div ref={passedRef} className={css.AboutSection}>
      <Text type="white" className={css.title} bold secondaryFamily>
        {t("aboutSection.title")}
      </Text>
      <Text type="white" className={css.subTitle}>
        {t(`aboutSection.subTitle.${city}`)}
      </Text>
      {contentBeforeImages.map((el) => (
        <Text key={el} type="white" className={css.content}>
          {el}
        </Text>
      ))}
      <div className={css.imagesOuterContainer}>
        {city === "warszawa" && (
          <img src={warsawBanner} className={css.warsawBaner}></img>
        )}
        <div className={css.imageContainer}>
          <div className={css.imageWrapper}>
            <img
              src={isMobile ? mobileImages[0] : images[0]}
              alt="first about me"
              title="About me image"
              className={css.image}
            />
          </div>
          <div className={css.imageWrapper}>
            <img
              src={isMobile ? mobileImages[1] : images[1]}
              alt="second about me"
              title="About me image"
              className={css.image}
            />
          </div>
          <div className={css.imageWrapper}>
            <img
              src={isMobile ? mobileImages[2] : images[2]}
              alt="third about me"
              title="About me image"
              className={css.image}
            />
          </div>
        </div>
        {contentAfterImages.map((el) => (
          <Text key={el} type="lighterPink" className={css.content}>
            {el}
          </Text>
        ))}
        <div className={css.imageContainer}>
          <div className={css.imageWrapper}>
            <img
              src={isMobile ? mobileImages[3] : images[3]}
              alt="fourth about me"
              title="About me image"
              className={css.image}
            />
          </div>
          <div className={css.imageWrapper}>
            <img
              src={isMobile ? mobileImages[4] : images[4]}
              alt="fifth about me"
              title="About me image"
              className={css.image}
            />
          </div>
          <div className={css.imageWrapper}>
            <img
              src={isMobile ? mobileImages[5] : images[5]}
              alt="sixth about me"
              title="About me image"
              className={css.image}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
